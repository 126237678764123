



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserData } from '../../../auth/shared'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<CustomerSupport>({
  name: 'CustomerSupport'
})
export class CustomerSupport extends Vue {
  @Prop({ type: Object, required: true })
  public userData!: Partial<Pick<UserData, 'email' | 'phone' | 'firstName' | 'lastName'>>
}

export default CustomerSupport
