






import { Component, Watch } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'
import { CustomerSupport } from '../../../front/shared/molecules/CustomerSupport'

import { CustomerSupportModule, CustomerSupportUserData } from '../CustomerSupport.contracts'
import { Inject } from '../../../support'
import {
  CustomerSupportServiceType,
  ICustomerSupportService
} from '../../../front/profile/services/customer-support'
import { UserModel } from '../../../front/auth/shared'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<CustomerSupportModuleUi>({
  name: 'CustomerSupportModuleUi',
  components: { CustomerSupport },

  created () {
    this.setCustomerSupportData()
  }
})
export class CustomerSupportModuleUi extends AbstractModuleUi<CustomerSupportModule> {
  @Inject(CustomerSupportServiceType)
  protected readonly customerSupportService!: ICustomerSupportService

  public userData: CustomerSupportUserData | null = null

  public get user (): UserModel | null {
    return this.$store.getters['profile/user']
  }

  @Watch('user')
  public setCustomerSupportData (): void {
    const billing = this.user?.getBillingAddress()
    const regionId = (billing && typeof billing.region !== 'string') ? billing?.region?.id : undefined

    this.userData = this.customerSupportService.getContactData(regionId)
  }

  public get shouldRender (): boolean {
    return this.userData !== null
  }
}

export default CustomerSupportModuleUi
